<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <!-- Organization -->
    <base-input
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="
        $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
        !hideOrganization
      "
    >
      <organization-selector
        :organization="serviceCenter.organization.id"
        :filterable="true"
        :showAll="false"
        @organizationChanged="
          (organizationId) => {
            serviceCenter.organization.id = organizationId;
            onFormChanged();
          }
        "
        :disabled="!!serviceCenter.id"
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />
        
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.NAME')} (*)`"
          :required="true"
          v-model="serviceCenter.name"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.name" />
      </div>     
    </div>

    <div class="my-4">
        <base-button
          type="button"
          class="btn btn-sm btn-primary"
          native-type="submit"
          :disabled="loading"
        >
          <i class="fas fa-spinner fa-spin" v-if="loading" />
          {{
            serviceCenter.id
              ? $t("SERVICECENTERS.EDIT_SERVICECENTER")
              : $t("SERVICECENTERS.ADD_SERVICECENTER")
          }}
        </base-button>
      </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import EstablishmentSelector from "@/components/EstablishmentSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    EstablishmentSelector,
  },

  mixins: [formMixin],

  props: [
    "serviceCenterData",
    "formErrors",
    "loading",
    "hideOrganization",
    "hideSubmit",
  ],

  data() {
    const me = this.$store.getters["profile/me"];
    const serviceCenterData = { ...this.serviceCenterData };
    if (this.$currentUserIsLevelOrganization()) {
      serviceCenterData.organization.id = me.organization.id;
    }
    return {
      serviceCenter: serviceCenterData,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      const serviceCenterData = cloneDeep(this.serviceCenter);
      const me = this.$store.getters["profile/me"];
      this.$emit("serviceCenterSubmitted", serviceCenterData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    serviceCenterData(serviceCenterData) {
      if (serviceCenterData) {
        this.serviceCenter = {
          ...this.serviceCenter,
          ...cloneDeep(serviceCenterData),
        };
      }
    },
  },
};
</script>
